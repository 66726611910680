<script>
    import { _, locale } from "svelte-i18n";
    let viewMore = false;

    // const goTo = (idSection) =>
    //     window.scrollTo({
    //         top: document.getElementById(idSection).offsetTop,
    //         behavior: "smooth",
    //     });
</script>

<div class="container-fluid">
    <div class="row">
        <div class="offset-md-1 col-md-5">
            <div class="section-titles testimonail area txtBenefist2">
                <div class="section-titles mb-35">
                    <h1 class="wow fadeInUp" data-wow-delay=".6s">
                        {$_("Improve your guest's experience")}
                    </h1>
                    <div
                        class="col-sm-12 d-sm-block d-md-none wow fadeInUp mt-20 mb-20"
                        data-wow-delay=".6s"
                    >
                        <div class="features-img">
                            <img
                                class="wow fadeInUp benefisSizeIgm"
                                data-wow-delay=".6s"
                                src="/img/benefits/benefits-1-{$locale}.webp"
                                alt="{$_("The guest making requests from the comfort of his or her phone")}"
                            />
                        </div>
                    </div>
                    <p class="wow fadeInUp" data-wow-delay=".6s">
                        {$_(
                            "Our platform provides them with the flexibility to create and track their requests."
                        )}
                    </p>

                    {#if viewMore}
                        <p style="margin: 15px 0 15px 0;">
                            {$_(
                                "A corporate traveler, a family on vacation or a couple on a romantic getaway all have unique needs/expectations and you now have the software to fulfill each one."
                            )}
                        </p>
                    {:else}
                        <p style="margin: 15px 0 15px 0;" />
                    {/if}

                    <a
                        style="color: #1AB394;"
                        class="wow fadeInUp imgBenefist1"
                        data-wow-delay=".6s"
                        href="#!"
                        on:click={() => (viewMore = !viewMore)}
                    >
                        {$_(viewMore ? "Hide" : "Learn more")}
                        <i style="position: absolute;" class="material-icons">
                            {viewMore ? "expand_less" : "expand_more"}
                        </i>
                    </a>
                    <div class="mt-40">
                        <a
                            class="btn wow fadeInUp styleBtn"
                            data-wow-delay=".6s"
                            href="#!"
                            on:click|preventDefault={() => goTo("contact")}
                            >{$_("Book demo")}</a
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 d-none d-md-block">
            <div class="features-img">
                <img
                    class="wow fadeInUp benefits-area-one"
                    data-wow-delay=".6s"
                    src="/img/benefits/benefits-1-{$locale}.webp"
                    alt="{$_("The guest making requests from the comfort of his or her phone")}"
                    style="width: 100%;"
                />
            </div>
        </div>
    </div>
</div>
