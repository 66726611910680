<script>
    import { _ } from "svelte-i18n";
    import CommunicationsComponent from "./communications.component.svelte";
    import RequestsComponent from "./request.component.svelte";
    import FoodBeverageComponent from "./food-beverage.component.svelte";
    import GuestAppComponent from "./guestapp.component.svelte";
    import PickupComponent from "./pickup.component.svelte";
    import InStayFeedback from "./in-stay-feedback.component.svelte";
    import GuestItemsComponent from "./guestItems.component.svelte";
    import TaskComponent from "./task.component.svelte";
    import CheckIn from "./check-in.svelte";
  import Promotions from "./promotions.component.svelte";
  
    let currentProduct = "requests";
    const products = [
        {
            icon: "img/products/requests/icon.svg",
            codename: "requests",
            title: "Requests",
        },
        {
            icon: "img/products/task/icon.svg",
            codename: "tasks",
            title: "Tasks",
        },
        {
            icon: "img/products/food-beverage/icon.svg",
            codename: "food-beverage",
            title: "Food & beverage",
        },
        {
            icon: "img/products/guestapp/icon.svg",
            codename: "guestapp",
            title: "GuestApp",
        },
        {
            icon: "img/products/communications/icon.svg",
            codename: "communications",
            title: "Communications",
        },
        {
            icon: "img/products/guest-items/icon.svg",
            codename: "guestItems",
            title: "Guest items",
        },
        {
            icon: "img/products/pickup/icon.svg",
            codename: "pickup",
            title: "Pickup",
        },
        {
            icon: "img/products/check-in/icon.svg",
            codename: "Check-in",
            title: "Check in",
        },
        {
            icon: "img/products/promotions/icon.svg",
            codename: "promotions",
            title: "Promotions",
        },
        {
            icon: "img/products/promotions/icon.svg",
            codename: "in-stay-feedback",
            title: "In stay Feedback",
        },
    ];
</script>

<section id="product">
    <section class="features-area pt-120 pb-160 pt-sm-80 pb-sm-80">
        <div class="container-fluid">
            <div class="wow fadeInUp" data-wow-delay=".6s">
                <div class="section-titles mb-30 text-center">
                    <h1>{$_("Products")}</h1>
                    <p>
                        {$_(
                            "All-in-one platform that streamlines operations and communication, boosts productivity, and increases guest satisfaction."
                        )}
                    </p>
                </div>
            </div>
            <div class="wow fadeInUp" data-wow-delay=".6s">
                <ul class="nav product-tabs hideScroll justify-content-center">
                    {#each products as product}
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <li class="nav-item mt-16"    on:click={() =>
                            (currentProduct = product.codename)}>
                            <a
                                class="nav-link {currentProduct ==
                                product.codename
                                    ? 'activate'
                                    : ''} "
                                aria-current="page"
                                href="#{product.codename}"
                             
                            >
                                <span class=" d-md-inline-block"
                                    >{$_(product.title)}</span
                                >
                            </a>
                        </li>
                    {/each}
                </ul>
            </div>

            {#if currentProduct == "requests"}
                <RequestsComponent id={currentProduct} />
            {:else if currentProduct == "tasks"}
                <TaskComponent id={currentProduct} />
            {:else if currentProduct == "food-beverage"}
                <FoodBeverageComponent id={currentProduct} />
            {:else if currentProduct == "guestapp"}
                <GuestAppComponent id={currentProduct} />
            {:else if currentProduct == "communications"}
                <CommunicationsComponent id={currentProduct} />
            {:else if currentProduct == "guestItems"}
                <GuestItemsComponent id={currentProduct} />
            {:else if currentProduct == "pickup"}
                <PickupComponent id={currentProduct} />
            {:else if currentProduct == "Check-in"}
                <CheckIn id={currentProduct} />
            {:else if currentProduct == "promotions"}
                <Promotions id={currentProduct} />
            {:else if currentProduct == "in-stay-feedback"}
                <InStayFeedback id={currentProduct} />
            {/if}
        </div>
    </section>
</section>
