<script>
    import { _, locale } from "svelte-i18n";

    const lists = [
        {
            icon: "img/products/requests/groups.svg",
            codename: "",
            title: "Internal (staff) requests",
        },
        {
            icon: "img/products/requests/room_service.svg",
            codename: "",
            title: "Guest Requests",
        },
        {
            icon: "img/products/requests/notifications.svg",
            codename: "",
            title: "Status notifications",
        },
        {
            icon: "img/products/requests/show_chart.svg",
            codename: "",
            title: "Escalations",
        },
    ];
</script>

<div class="container-fluid productSize">
    <div class="row" style="align-items: center;">
        <div class="offset-md-1 col-md-4">
            <div class="products-area">
                <div class="section-titles mb-35 mt-40">
                    <h2
                        class="wow fadeInUp "
                        data-wow-delay=".1s"
                    >
                        {$_("Requests")}
                    </h2>

                    <div class="col-sm-12 d-sm-block d-md-none">
                        <div class="features-img overflow nav product-tabs hideScroll justify-content-center ">
                            <img
                                class="wow fadeInUp  responsiveSm"
                                data-wow-delay=".6s"
                                src="/img/products/requets-{$locale}.webp"
                                alt={$_(
                                    "Guest request tracking in GuestApp and request monitoring"
                                )}
                            />
                        </div>
                    </div>

                    <p class="wow fadeInUp " data-wow-delay=".6s">
                        {$_(
                            "No task left behind. Capture, follow up and escalate all guest requests and internal tasks in a unified hub."
                        )}
                    </p>

                    <div
                        style="margin-top: 5%;"
                        class="features-text wow fadeInUp "
                        data-wow-delay=".6s"
                    >
                        {#each lists as item}
                            <div class="fs-4  testprod1">
                                <img
                                    alt=""
                                    width="50px"
                                    height="auto"
                                    class="bi bi-list imgProdGuestapp"
                                    src={item.icon}
                                />
                                <div class="testprod2">{$_(item.title)}</div>
                            </div>
                        {/each}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7 d-none d-md-block">
            <div class="features-img">
                <img
                    class="wow fadeInUp  nav product-tabs hideScroll justify-content-center "
                    data-wow-delay=".6s"
                    src="/img/products/requets-{$locale}.webp"
                    alt={$_(
                        "Guest request tracking in GuestApp and request monitoring"
                    )}
                    style="width: 100%;"
                />
            </div>
        </div>
    </div>
</div>
