<script>
    import { _, locale } from "svelte-i18n";

    const goTo = (idSection) =>
        window.scrollTo({
            top: document.getElementById(idSection).offsetTop,
            behavior: "smooth",
        });
</script>

<section
    id="home"
    class="slider-area slider-height-2 position-relative"
    data-background="/img/bg/bg2.png"
    style="background-image: url('/img/bg/bg2.png');"
>
    <div class="container-fluid">
        <div class="row">
            <div class="offset-md-1 col-md-4">
                <div class="slider-text slider-text-2 slider-content-space-2">
                    <h1 class="wow fadeInDown text-white" data-wow-delay=".3s">
                        {$_(
                            "A unified hub to manage and improve your guest experience"
                        )}
                        <strong> {$_("from reservation to review™")} </strong>
                    </h1>

                    <div
                        class="col-sm-12 col-xl-6 offset-xl-1 col-lg-7 offst-lg-1"
                    >
                        <div
                            class="slider-img-2 position-relative d-sm-block d-md-none mt-40 mb-40"
                        >
                            <img
                                class="wow fadeInDown"
                                data-wow-delay=".6s"
                                src="/img/slider/slider2-{$locale}.webp"
                                alt={$_(
                                    "A multi-device platform for the reception and the guest"
                                )}
                            />
                        </div>
                    </div>

                    <div class="slider-text-2-book-demo mt-20">
                        <a
                            class="btn wow fadeInDown styleBtn"
                            data-wow-delay=".6s"
                            href="#!"
                            on:click|preventDefault={() => goTo("contact")}
                            >{$_("Book demo")}</a
                        >
                    </div>
                </div>
            </div>
            <div class="d-none d-md-block col-md-7">
                <div class="slider-img-2 position-relative">
                    <img
                        class="wow fadeInDown"
                        data-wow-delay=".6s"
                        src="/img/slider/slider2-{$locale}.webp"
                        alt={$_(
                            "A multi-device platform for the reception and the guest"
                        )}
                        style="width: 100%;"
                    />
                </div>
            </div>
        </div>
    </div>
</section>
