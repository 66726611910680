<script>
    import { _, locale } from "svelte-i18n";

    const lists = [
        {
            icon: "img/products/promotions/description.svg",
            codename: "",
            title: "Allow guests to evaluate their experience instantly",
        },
        {
            icon: "img/products/promotions/task.svg",
            codename: "",
            title: "Identify and resolve problems in real time",
        },
        {
            icon: "img/products/promotions/interpretermode.svg",
            codename: "",
            title: "Create a relationship of trust by acting on guest feedback at the moment.",
        },
    ];
</script>

 <div class="container-fluid productSize">
    <div class="row pb-100" style="align-items: center;">
        <div class="offset-md-1 col-md-4">
            <div class="products-area">
                <div class="section-titles mb-35 mt-40">
                    <h2 class="wow fadeInUp " data-wow-delay=".1s">
                        {$_("In stay feedback")}
                    </h2>

                    <div class="col-sm-12 d-sm-block d-md-none">
                        <div class="features-img nav  overflow product-tabs hideScroll justify-content-center">
                            <img
                                class="wow fadeInUp  responsiveSm"
                                data-wow-delay=".6s"
                                src="/img/products/in-stay-feedback-{$locale}.webp"
                                alt={$_(
                                    "Promotions"
                                )}
                            />
                        </div>
                    </div>

                    <p class="wow fadeInUp " data-wow-delay=".6s">
                        {$_(
                            "Allow guests to evaluate the services they receive during their stay. Obtain immediate feedback and act proactively to improve the customer experience, ensuring their satisfaction before the end of their stay."
                        )}
                    </p>

                       <div
                        style="margin-top: 5%;"
                        class="features-text wow fadeInUp "
                        data-wow-delay=".6s"
                    >
                        {#each lists as item}
                            <div class="fs-4  testprod1">
                                <img
                                    alt=""
                                    width="50px"
                                    height="auto"
                                    class="bi bi-list imgProdGuestapp"
                                    src={item.icon}
                                />
                                <div class="testprod2">{$_(item.title)}</div>
                            </div>
                        {/each}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7 d-none d-md-block">
            <div class="features-img">
                <img
                    class="wow fadeInUp  nav product-tabs hideScroll justify-content-center"
                    data-wow-delay=".6s"
                    src="/img/products/in-stay-feedback-{$locale}.webp"
                    alt={$_(
                        "GuestApp order creation and F&B customization in Guesthub"
                    )}
                />
            </div>
        </div>
    </div>
</div>
