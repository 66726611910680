<script>
    import { _, locale } from "svelte-i18n";

    const lists = [
        {
            icon: "img/products/food-beverage/restaurant.svg",
            codename: "",
            title: "Customizable menus",
        },
        {
            icon: "img/products/food-beverage/shopping_cart.svg",
            codename: "",
            title: "Shopping cart",
        },
        {
            icon: "img/products/food-beverage/qr_code.svg",
            codename: "",
            title: "QR enabled menus",
        },
        {
            icon: "img/products/food-beverage/notifications.svg",
            codename: "",
            title: "Order management with notifications and escalations",
        },
    ];
</script>

<div class="container-fluid productSize">
    <div class="row pb-100" style="align-items: center;">
        <div class="offset-md-1 col-md-4">
            <div class="products-area">
                <div class="section-titles mb-35 mt-40">
                    <h2 class="wow fadeInUp " data-wow-delay=".1s">
                        {$_("Food and beverage")}
                    </h2>

                    <div class="col-sm-12 d-sm-block d-md-none">
                        <div class="features-img nav  overflow product-tabs hideScroll justify-content-center">
                            <img
                                class="wow fadeInUp  responsiveSm"
                                data-wow-delay=".6s"
                                src="/img/products/food-beverage-{$locale}.png"
                                alt={$_(
                                    "GuestApp order creation and F&B customization in Guesthub"
                                )}
                            />
                        </div>
                    </div>

                    <p class="wow fadeInUp " data-wow-delay=".6s">
                        {$_(
                            "Orders galore! Accept multiple F&B orders in less than a minute without the use of a phone. Your staff and guests will thank you."
                        )}
                    </p>

                    <div
                        style="margin-top: 5%;"
                        class="features-text wow fadeInUp "
                        data-wow-delay=".6s"
                    >
                        {#each lists as item}
                            <div class="fs-4  testprod1">
                                <img
                                    alt=""
                                    width="50px"
                                    height="auto"
                                    class="bi bi-list imgProdGuestapp"
                                    src={item.icon}
                                />
                                <div class="testprod2">{$_(item.title)}</div>
                            </div>
                        {/each}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7 d-none d-md-block">
            <div class="features-img">
                <img
                    class="wow fadeInUp  nav product-tabs hideScroll justify-content-center"
                    data-wow-delay=".6s"
                    src="/img/products/food-beverage-{$locale}.webp"
                    alt={$_(
                        "GuestApp order creation and F&B customization in Guesthub"
                    )}
                />
            </div>
        </div>
    </div>
</div>
